<!-- Envio Email Facturas Emitidas -->

<template>
  <div class="avisosFacturasEmail" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
      persistent>

        <template v-slot:controles="{}">
          <div class="columna">
                 
            <!-- Cabecera -->
            <div class="cabecera">
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'                
                @onEvent="eventHeader">
              </baseHeader>
            </div> 

            <v-sheet :elevation="4">
              <div class="contenedor">
                     
                <!-- Botonera Extra -->
                <baseBtraExtra
                  style="width:40px"
                  :permExtra="permExtra"
                  :modulo="btExtCfg"           
                  @onEvent="execAccion">                          
                </baseBtraExtra>
                                  
                <!-- Controles del Mto -->
                <div class="ctrls conflex contenedor max-width:1240px"> 
                  <div class="columna" style="width:550px">              
                    <uiText                       
                      v-model="ct.email[2]"
                      :label="ct.email[1]">
                    </uiText>

                    <v-textarea
                      v-bind="$textarea"
                      v-model="ct.txt[2]"
                      :label="ct.txt[1]"
                      rows="3"
                      no-resize>
                    </v-textarea>
                  </div>
                </div>  
              </div>
            </v-sheet>
          </div>
        </template>        
    </dualTemplate>    
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";  
  import baseHeader from "@/base/baseHeader";     
  import baseBtraExtra from "@/base/baseBtraExtra";
  import dualTemplate from "@/components/dualTemplate";  
    
  export default {
    mixins: [mixM],
    components: { baseHeader, baseBtraExtra, dualTemplate },
    props: {},

    data() {
      return {
        stIni: {
          api: "avisosFacturasEmailM",
          name:"avisosFacturasEmailM",
          titulo:"Avisos - Documentacón - Email",  
          recordAccess:"masterStore",
          masterStore:this.masterStore,
          mView:'',
          pView:[]
        }, 
                
      };
    },    
   

    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // botones Extra del Header
        this.btExtCfg.btnsAccion= [
          { accion:'sendEmail', btn: "sendEmail" }         
        ];
        
      },


      // api Calls
      iniDataApi() { 
        this.apiArgs.sch= null;         
        this.apiArgs.visto= ["", 'visto', { tip:2, env:1, id:0, txt:'', ema:'' }, "avisos"];     
      },


      // gancho final de la acción 'ver'
      verFin() {        
        let txt=(!this.masterSyncRecord.visto_obs?"Para completar el expediente quedamos a la espera de ...":this.masterSyncRecord.visto_obs);
        this.$set(this.ct.txt, 2, txt);
      },

      
      // envio email
      sendEmail() { 
        if (!this.ct.txt[2]) return this.$root.$alert.open('Debe introducir Texto de Respuesta', 'info');
        if (!this.ct.email[2]) return this.$root.$alert.open('Debe introducir Correo Electrónico', 'info');
              
        let msg='<b><u>VISTO / OK Documento Recibido</u></b><br> Aceptamos / VISTO la Recepcion ?<br>' + 
            '<br>Se envia Correo Electronico a <b>' + this.ct.email[2] + '</b><br>';

        this.$root.$alert.open(msg , 'confirm', null, ["ACEPTAR", "CANCELAR"])
        .then(r=> { if (r== 0) this.emailContinue() }
        );
      },

    
      async emailContinue() {        
        let param = { apiArg: this.apiArgs.visto, args: { id:this.masterSyncRecord.id, txt: this.ct.txt[2], ema: this.ct.email[2] }};
        let apiResult = await this.$store.dispatch("apiCall", param);
        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // recargo finder maestro
        this.masterStoreUpdate('refresh');
        
        // cierro ventana
        this.$emit('onEvent', { accion:6 });
      },

    },


    computed: {
      sch() {
        return {
          email: [ 'email', 'Correo Electronico', "" ],
          adjuntos: [ 'adjuntos', 'Documentos adjuntos', "" ],
          txt: [ 'txt', 'Observaciones', "" ]
        }
      }
    }

  }
</script>
